import React, { useState, useEffect } from 'react'
import { Button, TextField, Box, Grid, Typography, Paper, Divider, Container } from '@mui/material'
//import { v4 as uuidv4 } from 'uuid'
import shortid from 'shortid'
import { addVCard, updateVCard, VCardData } from '../utils/api'
import QRCodePreview from './QRCodePreview'

interface VCardFormProps {
  onAddVCard: (vCard: VCardData) => void
  vCardToEdit?: VCardData
}

const VCardForm: React.FC<VCardFormProps> = ({ onAddVCard, vCardToEdit }) => {
  const [formData, setFormData] = useState<VCardData>({
    id: '',
    company: '',
    email: '',
    tel: '',
    street: '',
    postcode: '',
    city: '',
    country: '',
    website: '',
    facebook: '',
    instagram: '',
    tiktok: '',

    qrCodeUrl: '',
    vCardUrl: '',
    shortUrl: '',
    utmParams: '',
  })

  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)

  useEffect(() => {
    if (vCardToEdit) {
      setFormData(vCardToEdit)
      setQrCodeUrl(vCardToEdit.qrCodeUrl || null)
    }
  }, [vCardToEdit])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {}

    if (!formData.company) newErrors.company = 'Company is required'
    if (!formData.email) newErrors.email = 'Email is required'
    if (!formData.tel) newErrors.tel = 'Telephone is required'
    if (!formData.street) newErrors.street = 'Street is required'
    if (!formData.postcode) newErrors.postcode = 'Postcode is required'
    if (!formData.city) newErrors.city = 'City is required'
    if (!formData.country) newErrors.country = 'Country is required'

    setErrors(newErrors)

    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateForm()) return

    let updatedVCard: VCardData

    if (formData.id) {
      const updatedCard = await updateVCard(formData)
      updatedVCard = updatedCard
      onAddVCard(updatedVCard)
      setQrCodeUrl(updatedVCard.qrCodeUrl)
    } else {
      const id = shortid()
      const newCard = await addVCard({ ...formData, id })
      updatedVCard = newCard
      onAddVCard(updatedVCard)
      setQrCodeUrl(updatedVCard.qrCodeUrl)
    }
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Box component='form' onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Company'
                  name='company'
                  value={formData.company}
                  onChange={handleChange}
                  error={!!errors.company}
                  helperText={errors.company}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Telephone' name='tel' value={formData.tel} onChange={handleChange} error={!!errors.tel} helperText={errors.tel} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Street'
                  name='street'
                  value={formData.street}
                  onChange={handleChange}
                  error={!!errors.street}
                  helperText={errors.street}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Postcode'
                  name='postcode'
                  value={formData.postcode}
                  onChange={handleChange}
                  error={!!errors.postcode}
                  helperText={errors.postcode}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='City' name='city' value={formData.city} onChange={handleChange} error={!!errors.city} helperText={errors.city} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Country'
                  name='country'
                  value={formData.country}
                  onChange={handleChange}
                  error={!!errors.country}
                  helperText={errors.country}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Website' name='website' value={formData.website} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Facebook' name='facebook' value={formData.facebook} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Instagram' name='instagram' value={formData.instagram} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Tiktok' name='tiktok' value={formData.tiktok} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            <Button type='submit' variant='contained' fullWidth>
              {formData.id ? 'Update VCard' : 'Save VCard'}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}></Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center' }}>
          <QRCodePreview qrCodeUrl={qrCodeUrl} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default VCardForm
