import React from 'react'
import { Button } from '@mui/material'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import { downloadQRCodes } from '../utils/api'

interface DownloadButtonProps {
  hasQRCodes: boolean
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ hasQRCodes }) => {
  const handleDownload = async () => {
    const data = await downloadQRCodes()
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'qrcodes.zip')
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Button
      variant={hasQRCodes ? 'contained' : 'outlined'}
      color={hasQRCodes ? undefined : 'primary'}
      onClick={handleDownload}
      disabled={!hasQRCodes}
      startIcon={<FolderZipIcon />}>
      Download QR Codes
    </Button>
  )
}

export default DownloadButton
