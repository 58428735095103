// src/components/QRCodePreview.tsx
import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import noImageExample from '../assets/images/noimage_example.png'
import { config } from 'shared/dist'

interface QRCodePreviewProps {
  qrCodeUrl: string | null
}

const QRCodePreview: React.FC<QRCodePreviewProps> = ({ qrCodeUrl }) => {
  const handleDownload = () => {
    if (qrCodeUrl) {
      const link = document.createElement('a')
      link.href = `${config.apiBaseUrl}${qrCodeUrl}`
      link.download = 'qrcode.svg'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: 450,
      }}>
      <Typography variant='h6' gutterBottom>
        QR Code Preview
      </Typography>
      {qrCodeUrl ? (
        <img src={`${config.apiBaseUrl}${qrCodeUrl}`} alt='QR Code' style={{ width: 350, height: 350 }} />
      ) : (
        <img src={noImageExample} alt='MMS Generated QR' style={{ width: 350, height: 350, padding: 5 }} />
      )}
      <Button variant='contained' color='primary' onClick={handleDownload} sx={{ mt: 2 }} disabled={!qrCodeUrl}>
        Download
      </Button>
    </Box>
  )
}

export default QRCodePreview
