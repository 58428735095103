"use strict";
exports.__esModule = true;
exports.generateVCardContent = void 0;
var extractUsername = function (url) {
    var match = url.match(/(?:http(?:s)?:\/\/)?(?:www\.)?(?:facebook|instagram|tiktok)\.com\/(?:@|#!\/)?([^/?]*)/);
    return match ? match[1] : url;
};
var generateVCardContent = function (vCard) {
    var vCardData = "BEGIN:VCARD\nVERSION:3.0\n";
    if (vCard.company)
        vCardData += "FN:".concat(vCard.company, "\n");
    if (vCard.email)
        vCardData += "EMAIL:".concat(vCard.email, "\n");
    if (vCard.tel)
        vCardData += "TEL:".concat(vCard.tel, "\n");
    if (vCard.street || vCard.city || vCard.postcode || vCard.country)
        vCardData += "ADR:;;".concat(vCard.street, ";").concat(vCard.city, ";;").concat(vCard.postcode, ";").concat(vCard.country, "\n");
    if (vCard.website)
        vCardData += "URL:".concat(vCard.website, "\n");
    if (vCard.facebook) {
        var fbUser = extractUsername(vCard.facebook);
        vCardData += "X-SOCIALPROFILE;TYPE=facebook;x-user=".concat(fbUser, ":").concat(vCard.facebook, "\n");
        vCardData += "URL;TYPE=Facebook:".concat(vCard.facebook, "\n");
    }
    if (vCard.instagram) {
        var igUser = extractUsername(vCard.instagram);
        vCardData += "X-SOCIALPROFILE;TYPE=instagram;x-user=".concat(igUser, ":").concat(vCard.instagram, "\n");
        vCardData += "URL;TYPE=Instagram:".concat(vCard.instagram, "\n");
    }
    if (vCard.tiktok) {
        var ttUser = extractUsername(vCard.tiktok);
        vCardData += "X-SOCIALPROFILE;TYPE=tiktok;x-user=".concat(ttUser, ":").concat(vCard.tiktok, "\n");
        vCardData += "URL;TYPE=TikTok:".concat(vCard.tiktok, "\n");
    }
    vCardData += "END:VCARD";
    return vCardData;
};
exports.generateVCardContent = generateVCardContent;
