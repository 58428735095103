import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material'
import { Edit, Delete, QrCode } from '@mui/icons-material'
import { VCardData } from 'shared/dist'
import { deleteVCard } from '../utils/api'
import { trackQRCodeScan } from '../utils/analytics'
import { config } from 'shared/dist'

const VCardTable: React.FC<{ vCards: VCardData[]; onEdit: (vCard: VCardData) => void; onDelete: (id: string) => void }> = ({
  vCards = [],
  onEdit,
  onDelete,
}) => {
  const handleDelete = async (id: string) => {
    await deleteVCard(id)
    onDelete(id)
  }

  const handleQRCodeClick = (vCardId: string) => {
    trackQRCodeScan(vCardId)
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telephone</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vCards.map((vCard) => (
            <TableRow key={vCard.id}>
              <TableCell>{vCard.company}</TableCell>
              <TableCell>{vCard.email}</TableCell>
              <TableCell>{vCard.tel}</TableCell>
              <TableCell>
                {vCard.qrCodeUrl && (
                  <IconButton href={`${config.apiBaseUrl}${vCard.qrCodeUrl}`} target='_blank'>
                    <QrCode />
                  </IconButton>
                )}
                <IconButton onClick={() => onEdit(vCard)}>
                  <Edit />
                </IconButton>
                {vCard.id && (
                  <IconButton onClick={() => handleDelete(vCard.id)}>
                    <Delete />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VCardTable
