import React from 'react'
import { AppBar, Toolbar, Typography, Container, Box, CssBaseline } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  marginTop: theme.spacing(10),
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}))

const Content = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}))

const MainContent = styled(Box)(({ theme }) => ({
  display: 'block',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: theme.spacing(4),
}))

interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <StyledAppBar position='fixed'>
        <StyledToolbar>
          <Typography variant='h6' noWrap>
            MMCH QR Code Generator
          </Typography>
        </StyledToolbar>
      </StyledAppBar>

      <Content>
        <MainContent>{children}</MainContent>
      </Content>
    </Root>
  )
}

export default MainLayout
