// src/components/FileUpload.tsx
import React, { useState } from 'react'
import { Grid, Box, Button, Typography, Paper, Divider, Container } from '@mui/material'
import { uploadFile } from '../utils/api'
import { VCardData } from 'shared/dist'
import { styled } from '@mui/material/styles'
import { UploadFile } from '@mui/icons-material'

interface FileUploadProps {
  onUploadSuccess: (newVCards: VCardData[]) => void
}

const FileUpload: React.FC<FileUploadProps> = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      try {
        const newVCards = await uploadFile(formData)
        onUploadSuccess(newVCards)
        setUploadStatus('Upload successful!')
      } catch (error) {
        setUploadStatus('Upload failed. Please try again.')
      }
      setSelectedFile(null)
    }
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} gap={2}>
            <Typography variant='h6' gutterBottom>
              Upload Excel File
            </Typography>
            <Button variant='contained' component='label' tabIndex={-1} color='primary'>
              Choose File
              <VisuallyHiddenInput type='file' accept='.csv, .xlsx' hidden onChange={handleFileChange} />
            </Button>
            {selectedFile && (
              <Typography variant='body2' color='textSecondary'>
                {selectedFile.name}
              </Typography>
            )}
            <Button variant='outlined' color='primary' onClick={handleUpload} disabled={!selectedFile} startIcon={<UploadFile />}>
              Upload
            </Button>
            {uploadStatus && (
              <Typography variant='body2' color={uploadStatus === 'Upload successful!' ? 'green' : 'red'}>
                {uploadStatus}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='body1'>Ensure your Excel file has the following column headers:</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant='body2' color='textSecondary'>
              - Store / Name
              <br />
              - E-Mail Adresse
              <br />
              - Handynummer
              <br />
              - Street and number
              <br />
              - Post code
              <br />
              - city
              <br />
              - Website
              <br />
              - Facebook
              <br />
              - Instagram
              <br />- TikTok
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FileUpload
